@font-face {
  font-family: "NoirPro";

  font-weight: 300;
  font-style: normal;

  src: url("../gaxon/fonts/Montserrat-VariableFont_wght.ttf") format("truetype"),
    url("../gaxon/fonts/LeagueGothic-Regular-VariableFont_wdth.ttf") format("truetype"),
    url("../gaxon/fonts/FuturaRegular.ttf") format("truetype");
}

@font-face {
  font-family: 'Quentin';
  src: url('../../images/Design/Fuentes/QUENTIN.OTF') format('opentype');
}
@font-face {
  font-family: 'Poppins-Black';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-BLACK.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-BLACKITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-BOLD.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-BOLDITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-EXTRABOLD.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-EXTRABOLDITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-EXTRALIGHT.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-EXTRALIGHTITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-ITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-LIGHT.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-LIGHTITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-MEDIUM.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-MEDIUMITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-REGULAR.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-SEMIBOLD.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-SEMIBOLDITALIC.TTF') format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-THIN.TTF') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('../../images/Design/Fuentes/POPPINS/POPPINS-THINITALIC.TTF') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("NoirPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("NoirPro-Regular.woff") format("woff"),
    url("NoirPro-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("NoirPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("NoirPro-Medium.woff") format("woff"),
    url("NoirPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("NoirPro-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("NoirPro-SemiBold.woff") format("woff"),
    url("NoirPro-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("NoirPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("NoirPro-Bold.woff") format("woff"),
    url("NoirPro-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("NoirPro-Heavy.eot?#iefix") format("embedded-opentype"),
    url("NoirPro-Heavy.woff") format("woff"),
    url("NoirPro-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
