.ant-table-thead th {
  background-color: #4b6d9d !important;
  color: #fff !important;
  font-weight: bold !important;
}
.ant-list-header {
  background-color: #184f9d !important;
}
.ant-modal-header {
  background: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-iconsm4 {
  font-size: '22px';
}
.ant-modal-login {
  width: 400px;
  height: 300px;
}
.ant-modal-close {
  color: #fff !important;
}
.divG-profile {
  height: '100vh';
  display: 'flex';
  flex-direction: 'column';
}
.div30-profile {
  height: '30%';
  background-color: '#12466b';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}
.divImg-profile {
  width: '180px';
  height: '180px';
  border-radius: 50%;
  background-color: 'white';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}
.img-profile {
  width: '80%';
  height: '80%';
  border-radius: '15%';
}
.div60-profile {
  height: '60%';
  background-color: 'white';
}
.div50-profile {
  height: '50%';
  background-color: 'white';
  margin-top: 20px;
}
.ant-tabs-tab {
  color: white;
  /* Cambia el color del texto de las pestañas */
  font-weight: bold;
  /* Aplica negrilla al texto de las pestañas */
}
.logo {
  align-items: center;
  margin-bottom: 80px;
  margin-right: 60px;
  height: 40px;
}
.ant-menu-dark .ant-menu-item {
  color: #F28C0F;
  font-weight: 800;
}
.custom-menu {
  background-color: #0D0D0D !important;
}
.group {
  margin-bottom: 24px;
}
.name {
  font-weight: 600;
  font-size: 15px;
}
.content {
  padding: 16px;
}
.members {
  margin-top: 8px;
}
.info-line {
  margin-bottom: 24px;
}
.title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
}
.info {
  font-size: 14px;
}
.custom-range-picker {
  display: flex;
  position: relative;
  padding: 8px;
  margin-bottom: 8px;
  color: black;
  font-weight: bold;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-weight: bold;
  color: #0D0D0D;
}
.ant-picker-input > input::placeholder {
  color: #717171;
  font-weight: 800;
}
.ant-picker-input > input {
  font-weight: bold;
  color: #0D0D0D;
}
.custom-p-input {
  color: #717171;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 600;
}
.custom-icon-search {
  font-size: 19px;
  display: flex;
  margin: auto;
}
.menu-lateral ul {
  display: flex;
  flex-direction: column;
  /* Cambia la dirección de flexión a vertical */
  align-items: center;
  /* Centra los elementos horizontalmente */
  list-style: none;
  /* Quita los estilos de la lista */
  padding: 0;
  /* Elimina el relleno por defecto de la lista */
}
.menu-lateral ul li {
  margin-bottom: 0;
  /* Añade un espacio entre cada elemento de la lista */
}
.contacts-options {
  background-color: white;
  border-end-start-radius: 18px;
  border-end-end-radius: 18px;
  padding: 10px;
}
.contacts-options-movil {
  background-color: white;
  border-end-start-radius: 18px;
  border-end-end-radius: 18px;
  padding: 10px;
  display: flex;
  position: fixed;
}
.contacts-options p {
  font-size: 12px;
  /* Cambia el tamaño de la letra según sea necesario */
  font-family: 'Poppins-Regular';
  margin: auto;
  font-weight: bold;
  margin-right: 12px;
}
.icon-text-container {
  display: inline-flex;
  align-items: center;
}
.icon-text-container a {
  text-decoration: none;
  color: inherit;
}
.contact-icon {
  width: 30px;
  /* Ajusta el tamaño del icono según sea necesario */
  height: auto;
  /* Ajusta el tamaño del icono según sea necesario */
  margin-right: 10px;
  /* Espacio entre el icono y el texto */
}
.contact-icon-movil {
  width: 25px;
  margin-right: 10px;
  /* Espacio entre el icono y el texto */
}
.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu .div-nav-texts,
.menu .div-nav-btns {
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: 15px;
}
@media only screen and (max-width: 600px) {
  .menu .div-nav-texts,
  .menu .div-nav-btns {
    display: none;
  }
}
.menu .div-nav-btns {
  margin-left: auto;
  /* Mueve este div hacia la derecha */
}
.menu ul li {
  display: inline-block;
  margin: 13px;
  flex-grow: 1;
  text-align: center;
}
.menu ul li a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
}
.menu ul li:first-child {
  text-align: left;
}
.menu ul li:last-child {
  text-align: right;
}
.btn-create {
  background: #BADA55;
  color: white;
  font-weight: bold;
}
.btn-dowload {
  background: #425b5b;
  color: white;
  font-weight: bold;
}
.btn-update {
  font-weight: bold;
}
.main-container {
  display: flex;
  flex-direction: column;
}
.header-container {
  background-color: #12466b;
  padding: 20px;
}
.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header-title {
  font-size: 2rem;
  color: white;
  margin-bottom: 5px;
}
.header-subtitle {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 15px;
}
.year-buttons {
  margin-bottom: 20px;
}
.year-button {
  font-weight: bold;
  padding: 8px 6px;
  border-radius: 2px;
  line-height: 1;
  cursor: pointer;
  margin-right: 10px;
  background-color: #6fb7ff;
  color: white;
  border: none;
}
.white-text {
  color: white;
}
.container-results2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Establece la altura del contenedor al 100% del viewport */
}
.primary-text {
  color: #F28C0F;
  font-weight: bold;
}
.content-container {
  display: flex;
  margin-top: 15px;
}
.card {
  width: 400px;
  margin-right: 15px;
}
.card {
  width: 400px;
  margin-right: 15px;
}
.div-icon-tel {
  display: inline-flex;
}
.icon {
  color: #F28C0F;
  margin-bottom: auto;
  top: auto;
  margin-right: 10px;
  font-size: 25px;
}
.nav-logo {
  width: 200px;
  margin-left: 70px;
  margin-right: 50px;
}
@media only screen and (max-width: 600px) {
  .nav-logo-container {
    margin: auto;
  }
  .nav-logo {
    margin-left: 0;
    margin-right: 0;
    display: flexbox;
    height: 70px;
  }
}
.nav-contact-logo {
  width: 20px;
  margin-right: 12px;
}
.div-nav-texts {
  margin-top: 5px;
}
.chart-container div {
  max-height: 200px;
}
